<template lang="pug">
v-card(v-if="Object.keys(sailorDocument).length")
  v-card-title.d-flex.justify-space-between.mx-6
    div.text-uppercase.text-left {{ $t(`${Object.keys(sailorDocument.behavior)[0]}-${type}`) }}
      span(v-if="checkAccess('backOffice')") (ID: {{ sailorDocument.id }})
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-card-text
    v-divider
    Info(
      v-if="sailorDocument.behavior.viewInfoBlock && !$route.query.viewEditBlock"
      :sailorDocument="sailorDocument")

    Form(
      v-else-if="sailorDocument.behavior.viewEditBlock || $route.query.viewEditBlock"
      :Certificate="sailorDocument")

    EditStatus(
      v-else-if="sailorDocument.behavior.viewEditStatusBlock"
      :sailorDocument="sailorDocument")

    //- VerificationSteps(
    //-   v-else-if="sailorDocument.behavior.viewVerificationStepsBlock"
    //-   :sailorDocument="sailorDocument"
    //-   getFunctionName="getExperienceReferences")

    ViewPhotoList(
      v-else-if="sailorDocument.behavior?.viewFilesBlock"
      :files="sailorDocument.files"
      :sailorDocument="sailorDocument"
      :documentType="type"
      :deleteFile="removeFileExperienceCertificate"
      :editFile="updateFileExperienceCertificate"
      isShowEdit
      )
</template>

<script>

import { viewDetailedComponent, back, deleteConfirmation, copyToClipboard } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapActions, mapGetters } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  name: 'SailorExperienceDocument',
  components: {
    Info: () => import('@/views/Sailor/SailorExperience/Info.vue'),
    Form: () => import('@/views/Sailor/SailorExperience/Form.vue'),
    EditStatus: () => import('@/views/Sailor/SailorExperience/EditStatus/EditStatus.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewFile/ViewFile.vue'),
    VerificationSteps: () => import('@/components/atoms/VerificationSteps/VerificationSteps.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons')
  },
  data () {
    return {
      type: 'experience',
      comments: [],
      viewDetailedComponent,
      deleteConfirmation,
      checkAccess,
      back,
      controlButtons: [
        {
          id: 8,
          name: 'mdi-content-copy',
          action: () => copyToClipboard(location.href),
          color: '#42627e',
          tooltip: 'documentIconControl.copyLink'
        },
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewInfoBlock'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.info'
        },
        {
          id: 2,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditBlock'),
          checkAccess: () => checkAccess('experience', 'edit', this.sailorDocument),
          color: '#42627e',
          tooltip: 'documentIconControl.edit'
        },
        {
          id: 6,
          name: 'mdi-sync',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditStatusBlock'),
          checkAccess: () => checkAccess('experience', 'editStatus', this.sailorDocument),
          color: '#42627e',
          tooltip: 'documentIconControl.editStatus'
        },
        {
          id: 3,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewFilesBlock'),
          checkAccess: () => checkAccess('experience', 'view_file'),
          color: '#42627e',
          tooltip: 'documentIconControl.files'
        },
        // {
        //   id: 8,
        //   name: ' mdi-folder-eye-outline',
        //   action: () => viewDetailedComponent(this.sailorDocument, 'viewVerificationStepsBlock'),
        //   checkAccess: () => (this.sailorDocument.status_document.id === 34 || this.sailorDocument.status_document) && checkAccess('experience', 'verificationSteps', this.sailorDocument),
        //   color: '#dd7137ab',
        //   tooltip: 'documentIconControl.verificationSteps'
        // },
        {
          id: 4,
          name: 'mdi-trash-can-outline',
          action: () => this.deleteDocument(),
          checkAccess: () => checkAccess('experience', 'delete', this.sailorDocument),
          color: '#F73E07',
          tooltip: 'documentIconControl.delete'
        },
        {
          id: 5,
          name: 'mdi-close',
          action: () => this.$router.push({ name: 'experience-reference', params: { id: this.$route.params.id }, query: { ...this.$route.query } }),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['sailorDocumentByID']),
    ...mapState({
      id: state => state.sailor.sailorId,
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      sailorDocument: state => state.sailor.experienceById
    })
  },
  mounted () {
    this.getExperienceReferencesById({ ...this.$route.params })
  },
  methods: {
    ...mapActions(['getExperienceReferencesById', 'deleteExperienceCertificateById', 'removeFileExperienceCertificate',
      'updateFileExperienceCertificate']),

    deleteDocument () {
      deleteConfirmation(this).then(async confirmation => {
        if (confirmation) {
          const response = await this.deleteExperienceCertificateById({ ...this.$route.params })
          if (SUCCESS_CODE.includes(response.code)) {
            this.$notification.success('deletedDocument')
            back('experience-reference')
          } else {
            this.$notification.error('cantDeleteDoc')
          }
        }
      })
    }
  }
}

</script>
